// Decorative stuff
$border-radius: 0
$border-radius-lg: 0
$border-radius-sm: 0

// Colors
$link-color: #3A53A8
$link-hover-color: darken($link-color, 60%)
$link-hover-decoration: none

$header-color: #373a3c
$brand-primary: $link-color
$brand-danger: #e33

$theme-colors: ( primary: #666 )

@import 'bootstrap/scss/bootstrap'
@import 'bootstrap/scss/mixins'
@import 'bootstrap/scss/variables'

