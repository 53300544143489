@import ../typography/fonts

#mc_embed_signup, .search-form
  background: none
  font: 1em $proxima-nova
  @include box-shadow(none)
  input[type="email"]
    width: 100%
    @include media-breakpoint-up(xl)
      width: auto
      max-width: 100%
      min-width: 15em
  .form-control
    margin: 0 0.5em
    font: 1em $proxima-nova
    background-color: #fff
    border: 1px solid #666
    font-size: 0.8em
    padding: 0.25em 0.5em
    @include box-shadow(none)

#mc-embedded-subscribe
  width: 100%
  @include media-breakpoint-up(xl)
    width: auto
    max-width: 100%
    min-width: 5em

