// Place all the styles related to the releases controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import 'typography/fonts'

.release-img 
  margin: 0 0 1em 0
  border: 1px solid #eee

.buy-link.btn
  margin-top: 0.5em
  margin-bottom: 0.5em
  color: black
  font-size: 1.1em
