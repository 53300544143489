body 
  background: #ffffff

::selection 
  background: rgb(105, 105, 105)

hr 
  border: 0
  height: 2px
  background: #666
  margin: 1em auto

.gallery 
  clear: both
  display: block
  img 
    padding-bottom: 20px
  
  img.pull-left 
    padding-right: 20px
  
  img.pull-left:last-child 
    padding-right: 0

article 
  iframe 
    max-width: 100%

/* Navbar Styles */
header
  > .nav
    padding: 0
    margin: 0
    text-align: center
    li 
      display: block
      a
        display: block
        padding: 0
      &:last-child
        padding: 0
  .crowdfunding-progress-bar
    border-bottom: 1px solid black
    background-color: white
    padding: 0 1em
    .progress
      background-color: #ff0000
      padding: 0.2rem
      margin-left: -1em
      padding-left: 1em
      padding-right: 1em
      font-size: 0.8em
      line-height: 1.2em
      text-align: right
      height: 100%
      font-family: $proxima-nova-condensed
      font-weight: 700
      color: #fff
    .empty
      width: auto
      padding: 0.5rem
      font-size: 0.9em
      text-align: right
      font-family: $proxima-nova-condensed
      font-weight: 700
      a
        color: $header-color
        &:visited
          color: $header-color
        &:hover
          color: #fff

footer
  display: block

img
  max-width: 100%

/* Home page style */
.lead p
  font-size: 1em
  line-height: 1.5
  margin-bottom: 1em

/* Events page styles */
.event-meta, .release-meta, .post-meta, .podcast-meta
  margin-bottom: 0.75rem
  font-size: 1.1em
  color: #333
  line-height: 1.5em
  text-align: center
  a
    &:hover
      text-decoration: underline
  .btn
    font-family: $proxima-nova
    text-transform: none
    &.btn-lg
      font-size: 1.1em

article
  margin-top: 0.2em
  @include media-breakpoint-up(md)
    margin-top: 1em
  clear: both

section
  clear: both

.event-header
  float: left
  margin: 5px 1em 1em 0
  max-width: 320px
  height: auto

.event-facebook
  float: left
  line-height: 1.5em
  font-size: 0.8em
  color: #333

img.release-header
  max-width: 100%

.tracklist, .performer-list
  font-size: 0.75em
  tr.track, tr.performer
    border: none
    td
      border: none
    td.composer
      font-weight: bold
      min-width: 10em
    td.title
      font-style: italic

.no-border tr
  border: none
  td
    border: none

.table-no-border tr:hover
  background-color: auto

#content
  margin-top: 3em
  padding-top: 3em
  &.crowdfunding-active
    margin-top: 6em
  @include media-breakpoint-up(md)
    margin-top: 3em
    &.crowdfunding-active
      margin-top: 6em
      padding-top: 3em

.logo
  img
    max-height: 2em

.splash
  background-repeat-y: no-repeat
  background-repeat-x: no-repeat
  background-position-y: bottom
  background-size: cover
  .card
    border: 1px solid black
    background-color: rgba(255, 255, 255, 0.9)
  &-first
    margin-top: -6em
  &-radios
    background: url('/images/just_radios.jpg')
    background-repeat-y: no-repeat
    background-repeat-x: no-repeat
    background-position-y: bottom
    background-size: cover
  &-contralto
    background: url('/images/contralto.jpg')
    background-repeat-y: no-repeat
    background-repeat-x: no-repeat
    background-position-y: bottom
    background-size: cover
  &-hellrite
    background: url('/images/hellrite.jpg')
    background-repeat-y: no-repeat
    background-repeat-x: no-repeat
    background-position-y: bottom
    background-size: cover
  &-water
    background: url('/images/water.jpg')
    background-repeat-y: no-repeat
    background-repeat-x: no-repeat
    background-position-y: bottom
    background-size: cover
  min-height: 100vh
  > div
    margin-top: 2em

blockquote
  padding-left: 1em
  border-left: 4px solid #ccc

