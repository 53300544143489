// Place all the styles related to the events controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.event-img 
  width: 100%
  height: auto
  margin-bottom: 0.1em
  overflow: hidden
  .img-preview
    img 
      filter: blur(200px)
  img
    min-width: 100%
  @include media-breakpoint-up(md)
    margin-bottom: 1em
  & a:hover
    text-decoration: none
  .label
    font-weight: 300
    width: 100%
    padding-top: 0.5em
    .location
      font-size: 0.8em
      display: block
      margin-bottom: 0
    .teaser
      margin-top: 0.5em
      display: none

.event-more-link
  max-width: 100%
  text-align: center
  .btn
    padding: 1em 0
    width: 100%
    height: 100%

.location
  font-family: $proxima-nova-condensed
  font-weight: 300
  font-size: 1em
  line-height: 1.5em

.calendar
  .calendar-month
    padding: 1em
    background-color: #333
    color: #fff
    font-family: $proxima-nova-condensed
    font-weight: 600
    font-size: 1.5em
    text-transform: uppercase
    border: 1px solid black
    .calendar-arrows
      color: #fff
      cursor: pointer
      a
        margin: 0 0.5em
        color: #fff
        text-decoration: underline
  ul
    display: grid
    margin: 0
    padding: 0
    grid-auto-flow: row
    grid-template-rows: repeat(5, 1fr)
    grid-template-columns: repeat(7, 1fr)
    border-left: 1px solid black
    li
      position: relative
      max-height: 14em
      min-height: 8em
      @include media-breakpoint-up(lg)
        max-height: 14em
        min-height: 8em
      overflow: hidden
      .bg-image
        background-size: cover
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        opacity: 0.2
        transition-property: opacity
        transition-duration: 1s
        &:hover
          opacity: 0.5
      .date
        font-family: $proxima-nova-condensed
        font-weight: 300
        font-size: 0.85em
      .event-title
        display: none
        @include media-breakpoint-up(md)
          display: inline-block
        position: relative
        font-family: $proxima-nova-condensed
        font-weight: 600
        font-size: 0.8em
        line-height: 1em
        a
          color: #333
          &:hover
            color: #000
      padding: 0.5em
      list-style-type: none
      border-right: 1px solid black
      border-bottom: 1px solid black

.media-sample
  margin: 1em 0
  width: 100%
  height: auto
  iframe
    width: 100%
    height: 360px
