@import fonts
@import icons

/* Typography */
body 
  font-family: $proxima-nova
  font-size: 18px

p 
  font-family: $proxima-nova
  font-size: 1.0em 
  line-height: 1.5em
  font-weight: 400
  margin: 0 0 1em

.logo,
h1, 
h2, 
h3, 
h4, 
h5
  font-family: $proxima-nova-condensed
  line-height: 1.1em
  margin-bottom: 0.5em
  font-weight: 500
  color: $header-color
  a 
    color: $header-color
    &:visited
      color: $header-color
    &:hover
      text-decoration: $header-color underline
      color: $header-color

a.h
  color: $header-color
  &:visited
    color: $header-color
  &:hover
    color: $header-color

.h1
  font-size: 1.5rem
  @include media-breakpoint-up(md)
    font-size: 3rem

h1
  font-size: 2em
  text-align: center

.trix-content
  h1
    text-align: left
    font-size: 1.5em

h2
  font-size: 1.75em
  .sidebar &
    text-align: center
h3
  font-size: 1.5em
  a &
    color: #333
    &:visited
      color: #333
h4
  font-size: 1.1em
  margin-bottom: 1em

h5
  font-size: 1.0em
  margin-bottom: 1em

@include media-breakpoint-up(md)
  h1
    font-size: 3em
  h2
    font-size: 2em
@include media-breakpoint-up(xl)
  h1
    font-size: 4.5em
  h2
    font-size: 2.5em

strong
  font-weight: bold

header
  .social-links
    display: block
  .nav
    li
      font-size: 0.9em
      a
        text-shadow: none
        color: $header-color
        padding: 0 0.125em
        &:hover
          color: $header-color
          text-decoration: $header-color underline
        &.active
          color: #666
        &.dropdown-item:hover
          background-color: transparent
          text-decoration: underline

      @include media-breakpoint-up(sm)
        display: inline-block
      @include media-breakpoint-up(md)
        padding-right: 10px

footer
  font-size: 1.1em
  line-height: 0.9em
  text-align: center
  a
    color: #333
    &:visited
      color: #333

/* Typekit: No Flash of Unstyled Text */
.wf-loading
  visibility: hidden

i.manicule
  background: url("/images/manicule.png")
  background-size: contain
  height: 1em
  width: 1em
  display: inline-block
  .text-muted &
    background: url("/images/manicule-muted.png")
    background-size: contain

.newspaper-columns
  @include media-breakpoint-up(md)
    column-count: 2
  @include media-breakpoint-up(lg)
    column-count: 3

/* Page-specific overrides */
.supporters
  > ul
    padding-left: 0
    > li
      font-weight: 900
      margin-top: 1em
      list-style: none
      font-size: 1.1em
      > ul
        padding-left: 0
        > li
          font-weight: 300
          margin-top: 0
          list-style: none
          font-size: 0.9em
