#artfully-alert 
  display: none

.performance
  padding: 0.5em 0.25em
  margin: 0.5em 0
  color: #666666
  border-bottom: 1px solid #cccccc
  position: relative

  .ticket-search
    position: absolute
    right: 0.25em

.ticket-types li 
  padding: 1em

  .ticket-type-name 
    font-size: 1.25em
    line-height: 1.5em

  .ticket-type-price 
    font-weight: bold 
    background: #eeeeee
    padding: 0.5em
    margin-right: 1em
    float: left
    font-size: 0.8em

  form 
    margin: 1em 0

#shopping-cart 
  position: fixed
  bottom: 0
  left: 0
  right: 0
  background-color: rgba(255,255,255,0.9)

  #shopping-cart-controls 
    color: white
    padding: 10px 5px 10px 27px
    cursor: pointer
    font-size: 0.9em
    right: 0
    width: 300px
    iframe
      width: 100%
    .timer
      float: right
    .shown & 
      background: #333 url("/assets/icons/down_arrow.png") no-repeat 10px center
    .hidden &
      background: #333 url("/assets/icons/up_arrow.png") no-repeat 10px center

#donation 
  input
    border: 1px solid #666
    font-size: 0.8em
    padding: 0.25em 0.5em

    &[type="text"] 
      margin: 5px 5px 5px 0
      width: 5em
    &[type="submit"]
      background-color: #fff

.ticket_count 
  margin-right: 5px

.preset-amount
  float: left
  width: 100px
  margin-bottom: 10px
