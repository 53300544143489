// Place all the styles related to the Podcasts controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.podcast-img 
  position: relative
  border: 1px solid #eee
  width: 100%
  height: auto
  margin-bottom: 30px
  img
    min-width: 100%
  & a:hover
    text-decoration: none
  .label
    position: absolute
    bottom: 0
    left: 0
    text-align: left
    font-weight: 300
    background: rgba(0, 0, 0, 0.8)
    width: 100%
    padding: 0.5em
    .title
      color: #ddd
      display: block
      margin-bottom: 0
    .teaser
      margin-top: 0.5em
      display: none
      p
        color: #ddd

audio.podcast
  width: 100%

